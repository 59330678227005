@media (min-width: 993px) {
  #member {
    margin-left: 232px;
    padding-top: 0px;

    header {
      border-top: 10px solid #000;
      background: #00495b;
      position: fixed;
      top: 0;
      left: 0;
      min-width: 100%;
      display: flex;
      height: 80px;
      align-items: center;
      z-index: 5000;
      justify-content: space-between;
      h1 {
        margin-bottom: 0;
        font-weight: bold;
        a {
          font-size: 20px;
          color: #fff;
          padding-left: 20px;
          display: block;
          img {
            max-width: 190px;
          }
          span {
            font-weight: normal;
            font-size: 14px;
            padding-left: 20px;
          }
        }
      }
      .logout {
        color: #ffffff;
        margin-right: 20px;
      }
    }

    h2 {
      text-align: center;
      color: #00495b;
      span {
        margin-top: 6px;
        font-size: 16px;
        color: #000000;
        display: block;
      }
    }

    .nav li {
      width: 100%;
      padding-bottom: 10px;
    }

    .navbar-collapse {
      align-items: stretch;
    }

    .navbar {
      padding: 0;
    }

    .navbar.fixed-left {
      position: fixed;
      top: 80px;
      left: 0;
      right: 0;
      z-index: 1030;
    }

    .navbar.fixed-left {
      bottom: 0;
      width: 232px;
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    .navbar.fixed-left .navbar-collapse {
      flex-grow: 0;
      flex-direction: column;
      width: 100%;
    }

    .navbar.fixed-left .navbar-collapse .navbar-nav {
      flex-direction: column;
      width: 100%;
    }

    .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item {
      width: 100%;
      margin: 0;
      padding: 0;
      a {
        padding: 15px 15px;
        color: #00495b;
        border-bottom: 1px solid #b2c8ce;
      }
      a:hover {
        background-color: #b2c8ce;
        text-decoration: none;
        color: #ffffff;
        border-bottom: 1px solid #ffffff;
        opacity: 1;
      }
      a.active {
        background-color: #b2c8ce;
        color: #ffffff;
        border-bottom: 1px solid #ffffff;
      }
      ul {
        padding: 0;
        margin-top: 5px;
        li {
          padding: 10px 15px 0;
          a {
            font-size: 14px;
            border: none;
            padding: 0;
          }
          a:hover {
            background-color: transparent;
            text-decoration: none;
            color: #00495b;
            border-bottom: none;
            opacity: 0.7;
          }
        }
      }
    }

    .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item .dropdown-menu {
      top: 0;
    }

    .navbar.fixed-left {
      right: auto;
    }

    .navbar.fixed-left .navbar-nav .nav-item .dropdown-toggle:after {
      border-top: 0.3em solid transparent;
      border-left: 0.3em solid;
      border-bottom: 0.3em solid transparent;
      border-right: none;
      vertical-align: baseline;
    }

    .navbar.fixed-left .navbar-nav .nav-item .dropdown-menu {
      left: 100%;
    }

    .main {
      margin-top: 80px; //header
      padding-top: 50px;
      min-height: 100%;
      height: 100%;
    }
  }

  #member.col1 {
    margin-left: 0px;
    header {
      height: 108px;
      h1 {
        width: 100%;
        text-align: center;
        a {
          padding: 0;
          img {
            max-width: 183px;
          }
          span {
            display: block;
            margin-top: 10px;
            padding: 0;
          }
        }
      }
    }
    .main {
      margin-top: 108px; //header
    }
  }
}

footer {
  text-align: center;
  font-size: 12px;
  p.foot-nav {
    font-size: 14px;
  }
  p {
    padding: 0;
    margin: 0;
  }
  a {
    color: #000;
    padding: 0 5px;
  }
  .foot-bottom {
    background-color: #cccccc;
    padding: 10px 0;
  }

  .bc {
    text-align: left;
    margin-bottom: 5px;
    font-size: 12px;
    a {
      font-size: 12px;
      color: #000;
    }
    span {
      margin: 0 10px;
    }
  }
}

#member {
  height: 100%;
  nav {
    border-right: 1px solid #b3b3b3;
  }
  .nav li a {
    color: #000;
    width: 100%;
    display: block;
    cursor: pointer;
  }
}

#member.information {
  header {
    border-top: 10px solid #666666;
    background-color: #bfa45a;
  }

  h2 {
    color: #bfa45a;
  }

  nav {
    background-color: #bfa45a;
    border-top: 10px solid #666666;
  }
}

@media (max-width: 992px) {
  #member {
    h2 {
      text-align: center;
      color: #00495b;
      font-size: 5vw;
      span {
        margin-top: 6px;
        font-size: 3vw;
        color: #000000;
        display: block;
      }
    }

    nav {
      background-color: #00495b;
      border-top: 10px solid #000;
      border-right: none;
    }
    nav h1 {
      margin: 0;
      padding-left: 10px;
    }

    nav h1 a {
      color: #fff;
      font-size: 4vw;
      display: block;
    }

    .navbar {
      padding: 0;
    }

    .main {
      margin-top: 97px;
      padding-top: 30px;
    }

    .navbar.fixed-left {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 5000;
    }

    .navbar-toggler {
      border: none;
      width: 40px;
      height: 40px;
      text-align: center;
    }

    .nav {
      margin-top: 0px;
      li {
        border-top: 1px solid #fff;
        width: 100%;

        a {
          padding: 20px 10px;
          color: #fff;
          width: 100%;
          display: block;
        }
        ul {
          padding: 0;
          border-top: 1px solid #fff;
          li {
            padding: 20px 10px;
            border-top: none;
            a {
              font-size: 12px;
              padding: 0;
            }
          }
          li:first-child {
            padding: 20px 10px 0px 10px;
          }
        }
      }
    }

    .toggle:focus {
      outline: none;
    }

    .toggle i,
    .toggle i:before,
    .toggle i:after {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      margin: 0;
      width: 20px;
      height: 1px;
      background: #fff;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
    }
    .toggle i:before {
      content: "";
      margin-top: -9px;
    }
    .toggle i:after {
      content: "";
      margin-top: 8px;
    }
    .toggle.js-open i {
      -webkit-transform: translate3d(-1px, 0, 0) rotate(-45deg);
      transform: translate3d(-1px, 0, 0) rotate(-45deg);
    }
    .toggle.js-open i:before {
      width: 50%;
      -webkit-transform: translate3d(5px, 3px, 0) rotate(90deg);
      transform: translate3d(5px, 3px, 0) rotate(90deg);
    }
    .toggle.js-open i:after {
      width: 50%;
      -webkit-transform: translate3d(5px, -3px, 0) rotate(90deg);
      transform: translate3d(5px, -3px, 0) rotate(90deg);
    }

    .button_outer {
      width: 100%;
      padding: 10px 0;
      h1 {
        width: 100%;
        text-align: center;
        padding: 0;
        a {
          display: inline-block;
          vertical-align: top;
        }
        img {
          vertical-align: middle;
          height: 43px;
        }
        div {
          font-size: 12px;
          padding-top: 10px;
        }
      }
      button {
        position: fixed;
        top: 30px;
        right: 0;
      }
    }
  }

  #member.col1 {
    .main {
      margin-top: 75px;
    }

    .button_outer {
      h1 {
        a {
          display: inline-block;
          vertical-align: top;
          width: 144px;
        }
      }
    }
  }
}